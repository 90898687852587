import { getUrl } from './base-url';
import type { BreadcrumbList, ListItem } from 'schema-dts';

export function breadcrumbs(pathname: string, items: Array<{ name: string; pathname: string }>): BreadcrumbList {
	return {
		'@type': 'BreadcrumbList',
		'@id': getUrl(pathname, 'breadcrumblist'),
		itemListElement: items.map(
			({ pathname, name }, index): ListItem => ({
				'@type': 'ListItem',
				position: index + 1,
				name,
				item: { '@id': getUrl(pathname), name },
				nextItem: items.length > index + 1 ? { '@id': getUrl(items[index + 1]!.pathname) } : undefined,
			}),
		),
	};
}
